<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} Colegiatura`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Nombre colegiatura:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="tuition.nombre_colegiatura"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Descripción:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-text-field
            v-model="tuition.descripcion"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Monto:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-text-field
            v-model.number="tuition.monto"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Moneda:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="tuition.id_moneda"
            dense
            outlined
            :rules="[rules.required]"
            :items="coins"
            item-value="id"
            item-text="nombre_moneda"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de colegiaturas"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeTuitions">
          <v-list-item :key="item.nombre_descuento">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === tuition.id"
                  class="mb-0 pb-0"
                  v-model="tuition.nombre_colegiatura"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_colegiatura"
                />
                <v-text-field
                  v-if="toUpdate && item.id === tuition.id"
                  class="mb-0 pb-0"
                  v-model="tuition.descripcion"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.descripcion"
                />
                <v-text-field
                  v-if="toUpdate && item.id === tuition.id"
                  class="mb-0 pb-0"
                  v-model="tuition.monto"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`$${item.monto}`"
                />
                <v-select
                  v-if="toUpdate && item.id === tuition.id"
                  v-model="tuition.id_moneda"
                  dense
                  outlined
                  :items="coins"
                  item-value="id"
                  item-text="nombre_moneda"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.moneda.nombre_moneda"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === tuition.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeTuitions.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [dateUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      tuition: {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      tuitions: [],
      coins: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeTuitions() {
      return this.tuitions.filter(tuition => tuition.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.tuition)
    }
  },
  async created() {
    this.tuition.id_autor = this.userData.id
    this.tuition.id_institucion_educativa = this.institutionId
    this.tuitions = await this.fetchTuitionsByEI(this.institutionId)
    this.coins = await this.fetchCoinsByIE(this.institutionId)
  },
  methods: {
    async fetchTuitionsByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/colegiatura-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener descuentos pronto pago por institucion educativa', error);
      }
    },
    async fetchCoinsByIE(institutionId) {
      try {
        const response = await api.get(`/administracion/api/moneda-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta monedas por institucion educativa.', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener monedas por institucion educativa', error);
      }
    },
    async createTuition() {
      try {
        const response = await api.post('/personas/crear-colegiatura', {
          nombre_colegiatura: this.tuition.nombre_colegiatura,
          descripcion: this.tuition.descripcion,
          monto: this.tuition.monto,
          id_moneda: this.tuition.id_moneda,
          id_institucion_educativa: this.tuition.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la colegiatura.')
        }
      } catch (error) {
        console.error('Error al intentar crear la colegiatura', error)
        this.errors.push('Error al intentar crear la colegiatura')
      }
    },
    async updateTuition() {
      try {
        const response = await api.patch('/personas/update-colegiatura', {
          id: this.tuition.id,
          nombre_colegiatura: this.tuition.nombre_colegiatura,
          descripcion: this.tuition.descripcion,
          monto: this.tuition.monto,
          id_moneda: this.tuition.id_moneda,
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la colegiatura')
        }
      } catch (error) {
        console.error('Error al intentar actualizar la colegiatura', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteTuition(tuitionId) {
      try {
        const response = await api.post('/personas/delete-colegiatura', {
          id_colegiatura: tuitionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la colegiatura')
        }
      } catch (error) {
        console.error('Error al intentar eliminar la colegiatura', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateTuition()
        this.toUpdate = false
      } else {
        await this.createTuition()
        this.clear()
      }
      this.tuitions = await this.fetchTuitionsByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.tuition = {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.tuition = {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.tuition = {...item}
      this.tuition.id_moneda = item.moneda.id
      this.tuition.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.tuition);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteTuition(itemId)
      this.tuitions = await this.fetchTuitionsByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>